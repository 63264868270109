import { Grid, Typography, Box } from '@mui/material';
import { Basket2Icon, TabControl } from '../../../common';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { FileHandlingComponent } from '../component/file-handling';
import { SearchNutritionComponent } from '../component/search-nutrition';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CombinedState, isEmpty, MENU_HREF } from '../../../core';
import { useDispatch, useSelector } from 'react-redux';
import { getMasterInfoAsync } from '../data-access/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textHeader: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    error: {
      margin: 0,
      fontSize: '0.75rem',
      marginTop: `3px !important`,
      textAlign: 'left',
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
      color: (theme.palette.common as any).color.textError,
    },
  }),
);

export const ProductPricingPage = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();
  const { masterInfo } = useSelector((state: CombinedState) => state['productPricing']);

  const source = [
    {
      tabLabel: 'インポート・エクスポート',
      children: <FileHandlingComponent />,
      tabIndex: 0,
    },
    {
      tabLabel: '商品価格の検索',
      children: <SearchNutritionComponent />,
      tabIndex: 1,
    },
  ];

  const onChange = (tabIndex: number) => {
    setTabIndex(tabIndex);
    navigate(MENU_HREF.PRODUCT_PRICING, {
      replace: true,
      state: { ...state, tabIndex: tabIndex },
    });
  };

  useEffect(() => {
    if (!isEmpty(state)) {
      setTabIndex(state.tabIndex);
    } else {
      navigate(MENU_HREF.PRODUCT_PRICING, {
        replace: true,
        state: { ...state, tabIndex: tabIndex },
      });
    }
    dispatch(getMasterInfoAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display='flex' alignItems='center' mb={1}>
          <Basket2Icon fontSize='large' width={16} height={16} viewBox={'0 0 16 16'} />
          <Typography ml={1} variant='h2' className={classes.textHeader}>
            企業商品価格
          </Typography>
        </Box>
        <Typography component='span'>
          企業商品価格マスタ更新日時：{masterInfo?.updatedDatetime ? masterInfo?.updatedDatetime : 'なし'}
        </Typography>
      </Grid>
      <Grid item xs={12} mt={3}>
        <TabControl activeIndex={tabIndex} source={source} onChangeTab={onChange} />
      </Grid>
    </Grid>
  );
};
