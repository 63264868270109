import { Box, Link, Stack, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileDownloadIcon, FileInputControl } from '../../../../common';
import { CombinedState, CONTENT_TYPE, downloadTemplateAsync } from '../../../../core';
import { importProductPriceAsync } from '../../data-access/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textHeader: {
      fontSize: '1.5rem',
    },
    linkDescription: {
      marginTop: '0.2em !important',
    },
    linkText: {
      color: (theme.palette.common as any).color.primary,
      cursor: 'pointer',
    },
  }),
);

export const ImportComponent = () => {
  const classes = useStyles();
  const { productPricingFetching } = useSelector((state: CombinedState) => state['productPricing']);
  const [file, setFile] = useState<any>(undefined);
  const dispatch = useDispatch();

  const onChangeFile = (file: any) => {
    setFile(file);
  };

  const onImport = () => {
    dispatch(importProductPriceAsync(file));
  };

  const onDownloadTemplate = () => {
    dispatch(downloadTemplateAsync('6'));
  };

  return (
    <Stack spacing={2}>
      <Typography>
        企業商品価格の一覧をファイルからインポートしたり、登録済みの企業商品価格の一覧をエクスポートできます。
      </Typography>
      <Stack spacing={1}>
        <Box display='flex' alignItems='center'>
          <FileDownloadIcon fontSize='medium' viewBox={'0 0 16 16'} />
          <Typography ml={1} variant='h4' className={classes.textHeader}>
            企業商品価格一覧ファイルの読み込み
          </Typography>
        </Box>
        <Box px={3} display='flex' flexDirection='column'>
          <FileInputControl
            buttonName='インポート'
            accept={`.${CONTENT_TYPE.XLS},.${CONTENT_TYPE.XLSX}`}
            allowExtensions={[CONTENT_TYPE.XLS, CONTENT_TYPE.XLSX]}
            onChange={onChangeFile}
            onImport={onImport}
            loading={productPricingFetching}
          />
          <Typography component='span' className={classes.linkDescription}>
            インポートファイルの雛形は、
            <Link onClick={onDownloadTemplate} className={classes.linkText}>
              こちらのファイル
            </Link>
            をご確認ください。
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};
