export const PASSWORD_SIMILAR_OLD = 'このパスワードは一般的すぎます。';
export const PASSWORD_SIMILAR_NEW = 'このパスワードは簡単すぎます。英数字混合で複雑なパスワードにする必要があります。';
export const PASSWORD_NUMBER_OLD = 'このパスワードは数字しか使われていません。';
export const PASSWORD_NUMBER_NEW = 'パスワードは英数字混合で作成する必要があります。';
export const EMAIL_IS_EXISTS_OLD = [
  '他のユーザーがこのメールアドレスを使用しています。',
  'このメールアドレスを持ったユーザーが既に存在します。',
];
export const EMAIL_IS_EXISTS_NEW = 'このメールアドレスは既に登録されています。';
export const MAX_FILE_SIZE = 10 * 1024 * 1024;
export const MAX_FILE_SIZE_ERROR = 'アップロードできる最大ファイルサイズは10MBまでとなります。';
export const DOWNLOAD_QR_SUCCESS_MSG = 'ダウンロードに成功しました。'
export const DOWNLOAD_QR_FAILED_MSG = 'ダウンロードに失敗しました。'

export const enum MENU {
  DASHBOARD = 'dashboard',
  PURCHASE_LIMIT = 'purchase-limit',
  REBATE_AMOUNT = 'rebate-amount',
  IMPORT = 'import',
  EXPORT = 'export',
  SETTING = 'setting',
  ACCESS_LOG = 'access-log',
  EVENT_LOG = 'event-log',
  ORDER_DATA_OUTPUT = 'order-data-output',
  IMPORT_FILES_GOOD = 'import-files-good',
  IMPORT_FILES_INVENTORY = 'import-files-inventory',
  IMPORT_FILES_CALENDAR = 'import-files-calendar',
  REBATE_OUTPUT = 'export-rebate-output',
  QR_DOCUMENT = 'qr-list',
  PRODUCT_PRICING = 'product-pricing',
}

export const enum MENU_HREF {
  DASHBOARD = '/dashboard',
  PURCHASE_LIMIT = '/purchase-limit',
  REBATE_AMOUNT = '/rebate-amount',
  IMPORT = '/import-files',
  SETTING = 'setting',
  ACCESS_LOG = '/setting-accesslog',
  EVENT_LOG = '/setting-eventlog',
  EXPORT = '/output-files',
  EXPORT_NUTRITION = '/output-files/nutrition',
  ROOT = '/',
  QR_DOCUMENT = '/qr-list',
  QR_ACCESS_COUNT = '/qr-access-count',
  QR_CODE = '/qr-code',
  QR_CODE_EDIT = '/qr-code/:hb_id',
  QR_SCAN = '/qr-scan',
  QR_DOWNLOAD = '/qr-download',
  PRODUCT_PRICING = '/company-product-pricing',
}

export const enum SCREEN_NAME {
  DASHBOARD = 'ダッシュボード画面',
  PURCHASE_LIMIT = '購入上限画面',
  REBATE_AMOUNT = '栄養指導料画面',
  IMPORT = '取り込み画面',
  ACCESS_LOG = 'アクセスログ画面',
  EVENT_LOG = '処理状況ログ画面',
  EXPORT = '書き出し画面',
  PRODUCT_PRICING = '企業商品価格画面',
}

export const enum CONTENT_TYPE {
  XLSX = 'xlsx',
  XLS = 'xls',
  CSV = 'csv',
}

export const enum FUNCTION_CODE {
  NUTRITION_GUIDANCE_FEE = 1040,
  NUTRITION_GUIDANCE_FEE_IMPORT = 1041,
  PURCHASE_LIMIT = 1060,
  PRODUCT_GROUP = 1120,
  PRODUCT_GROUP_IMPORT = 1121,
  PRODUCT_GROUP_VALIDATION = 1122,
  INVENTORY = 1080,
  INVENTORY_IMPORT = 1081,
  INVENTORY_VALIDATION = 1082,
  NUTRITION_GUIDANCE_FEE_OBIC7_OUTPUT = 1045,
  NUTRITION_GUIDANCE_FEE_OBIC7_IMPORT = 1046,
  PURCHASE_LIMIT_IMPORT_MASTER = 1061,
  BUSINESS_DAY_CALENDAR = 1020,
  BUSINESS_DAY_CALENDAR_IMPORT = 1021,
  BUSINESS_DAY_CALENDAR_VALIDATION = 1022,
  LSC_SCREEN = 1220,
  PRODUCT_PRICING_IMPORT = 1331,
  PRODUCT_PRICING = 1330,
}

export const enum LOG_LEVEL {
  INFORMATION = 1,
  SUCCESS = 2,
  WARNING = 3,
  ERROR = 4,
  DEBUG = 5,
}

export const enum SORT_TYPE {
  ASCENDING = 'ASC',
  DESCENDING = 'DESC',
}

export const enum ALERT_TYPE {
  INFORMATION = 'info',
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  CUSTOM = 'custom',
}

export const enum MASTER_TYPE {
  EX_PURCHASE_LIMIT = '1',
  EX_ADVISOR_FEE_RATE = '2',
  EX_PRODUCT_PRICING = '3',
}

export const enum EXPORT_PURCHASE_TYPE {
  EX_PURCHASE_LIMIT = 1,
  EX_PURCHASE_LIMIT_SETTING = 0,
}

export const enum EXPORT_REBATE_TYPE {
  EX_ADVISOR_FEE_RATE = 1,
  EX_ADVISOR_FEE_RATE_SETTING = 0,
}
