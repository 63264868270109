import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { CombinedState, SORT_TYPE, isEmpty, numberWithCommas, ProductPricing } from '../../../../core';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { SortIcon } from '../../../../common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRoot: {
      width: '50%',
      minWidth: '50em',
      maxWidth: '90em',
      marginBottom: '1rem',
      tableLayout: 'fixed',
    },
    container: {
      minHeight: '15rem',
    },
    sortIcon: {
      color: (theme.palette.common as any).color.primary,
    },
  }),
);

interface ResultTableProps {
  onSort: (sortItem: string) => void;
  ordering: string;
}

export const ResultTableComponent = (props: ResultTableProps) => {
  const classes = useStyles();
  const { onSort, ordering } = props;
  const { productPrices } = useSelector((state: CombinedState) => state['productPricing']);
  const [sortSalesRoutType, setSortSalesRoutType] = useState<string>(SORT_TYPE.ASCENDING);
  const [sortJanCode, setSortJanCode] = useState<string>(SORT_TYPE.ASCENDING);

  const handleSort = (columnType: string) => {
    let sortType = '';
    switch (columnType) {
      case 'goods_id__wholesalers_goods_number':
        sortType = sortJanCode === SORT_TYPE.ASCENDING ? SORT_TYPE.DESCENDING : SORT_TYPE.ASCENDING;
        columnType =
          sortJanCode === SORT_TYPE.ASCENDING
            ? `-${columnType},sales_route_type__sales_route_type`
            : `${columnType},sales_route_type__sales_route_type`;
        setSortJanCode(sortType);
        setSortSalesRoutType(SORT_TYPE.ASCENDING);
        break;

      case 'sales_route_type__sales_route_type':
        sortType = sortSalesRoutType === SORT_TYPE.ASCENDING ? SORT_TYPE.DESCENDING : SORT_TYPE.ASCENDING;
        columnType =
          sortSalesRoutType === SORT_TYPE.ASCENDING
            ? `-${columnType},goods_id__wholesalers_goods_number`
            : `${columnType},goods_id__wholesalers_goods_number`;
        setSortSalesRoutType(sortType);
        setSortJanCode(SORT_TYPE.ASCENDING);
        break;
    }
    onSort(columnType);
  };

  useEffect(() => {
    if (isEmpty(ordering)) return;
    else {
      switch (ordering) {
        case 'goods_id__wholesalers_goods_number':
          setSortJanCode(SORT_TYPE.ASCENDING);
          break;
        case '-goods_id__wholesalers_goods_number':
          setSortJanCode(SORT_TYPE.DESCENDING);
          break;
        case 'sales_route_type__sales_route_type':
          setSortSalesRoutType(SORT_TYPE.ASCENDING);
          break;
        case '-sales_route_type__sales_route_type':
          setSortSalesRoutType(SORT_TYPE.DESCENDING);
          break;
      }
    }
  }, [ordering]);

  return (
    <TableContainer classes={{ root: classes.container }}>
      <Table classes={{ root: classes.tableRoot }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <Box display='flex' justifyContent='flex-start' alignItems='center'>
                <Typography className='lsc-table-header' component='span'>
                  受注元区分
                </Typography>
                <IconButton
                  className={classes.sortIcon}
                  onClick={() => handleSort('sales_route_type__sales_route_type')}
                >
                  <SortIcon color='inherit' type={sortSalesRoutType} viewBox={'0 0 16 16'} />
                </IconButton>
              </Box>
            </TableCell>
            <TableCell width='15%'>受注元区分名</TableCell>
            <TableCell>
              <Box display='flex' justifyContent='flex-start' alignItems='center'>
                <Typography className='lsc-table-header' component='span'>
                  JANコード
                </Typography>
                <IconButton
                  className={classes.sortIcon}
                  onClick={() => handleSort('goods_id__wholesalers_goods_number')}
                >
                  <SortIcon color='inherit' type={sortJanCode} viewBox={'0 0 16 16'} />
                </IconButton>
              </Box>
            </TableCell>
            <TableCell align='left'>商品名</TableCell>
            <TableCell align='right'>商品価格</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productPrices.results?.map((row: ProductPricing) => (
            <TableRow hover key={row.productPricingId}>
              <TableCell component='th' scope='row'>
                {row?.salesRouteType ?? ''}
              </TableCell>
              <TableCell>{row?.salesRouteTypeName ?? ''}</TableCell>
              <TableCell align='left'>{row?.janCode ?? ''}</TableCell>
              <TableCell align='left'>{row?.goodsName ?? ''}</TableCell>
              <TableCell align='right'>{numberWithCommas(row.goodsPrice)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {productPrices.count === 0 && <Typography component='span'>検索結果のデータが表示されます。</Typography>}
    </TableContainer>
  );
};
