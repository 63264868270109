import saveAs from 'file-saver';
import { getLogs, getSetting } from '../../../common';
import {
  ActionUnion,
  createAction,
  getApiError,
  getApiSuccess,
  getFileName,
  isEmpty,
  LogInfo,
  MASTER_TYPE,
  ProductPricingPagination,
  SearchCondition,
  SettingInfo,
  ThunkAction,
} from '../../../core';
import { exportFile, getProductPrices, importProduct } from '../service/services';

export enum ProductPricingActionTypes {
  GET_IMPORT_LOGS = 'PRODUCT_PRICING_GET_IMPORT_LOGS',
  GET_IMPORT_LOGS_SUCCESS = 'PRODUCT_PRICING_GET_IMPORT_LOGS_SUCCESS',
  GET_IMPORT_LOGS_FAILURE = 'PRODUCT_PRICING_GET_IMPORT_LOGS_FAILURE',

  GET_PRODUCT_PRICING = 'PRODUCT_PRICING_GET_PRODUCT_PRICING',
  GET_PRODUCT_PRICING_SUCCESS = 'PRODUCT_PRICING_GET_PRODUCT_PRICING_SUCCESS',
  GET_PRODUCT_PRICING_FAILURE = 'PRODUCT_PRICING_GET_PRODUCT_PRICING_FAILURE',

  IMPORT_PRODUCT_PRICING = 'IMPORT_FILES_DATA_PRODUCT_PRICING',
  IMPORT_PRODUCT_PRICING_SUCCESS = 'IMPORT_FILES_DATA_PRODUCT_PRICING_SUCCESS',
  IMPORT_PRODUCT_PRICING_FAILURE = 'IMPORT_FILES_DATA_PRODUCT_PRICING_FAILURE',

  GET_MASTER_INFO = 'PRODUCT_PRICING_GET_MASTER_INFO',
  GET_MASTER_INFO_SUCCESS = 'PRODUCT_PRICING_GET_MASTER_INFO_SUCCESS',
  GET_MASTER_INFO_FAILURE = 'PRODUCT_PRICING_GET_MASTER_INFO_FAILURE',

  PRODUCT_PRICING_EXPORT = 'PRODUCT_PRICING_EXPORT',
  PRODUCT_PRICING_EXPORT_SUCCESS = 'PRODUCT_PRICING_EXPORT_SUCCESS',
  PRODUCT_PRICING_EXPORT_FAILURE = 'PRODUCT_PRICING_EXPORT_FAILURE',
}

export const productPricingActions = {
  getImportLogs: () => createAction(ProductPricingActionTypes.GET_IMPORT_LOGS),
  getImportLogsSuccess: (data: LogInfo[]) => createAction(ProductPricingActionTypes.GET_IMPORT_LOGS_SUCCESS, { data }),
  getImportLogsFailure: (error: any) =>
    createAction(ProductPricingActionTypes.GET_IMPORT_LOGS_FAILURE, {
      error,
    }),

  getProductPricing: () => createAction(ProductPricingActionTypes.GET_PRODUCT_PRICING),
  getProductPricingSuccess: (data: ProductPricingPagination) =>
    createAction(ProductPricingActionTypes.GET_PRODUCT_PRICING_SUCCESS, { data }),
  getProductPricingFailure: (error: any) =>
    createAction(ProductPricingActionTypes.GET_PRODUCT_PRICING_FAILURE, {
      error,
    }),

  getMasterData: () => createAction(ProductPricingActionTypes.GET_MASTER_INFO),
  getMasterDataSuccess: (masterInfo: SettingInfo) =>
    createAction(ProductPricingActionTypes.GET_MASTER_INFO_SUCCESS, { masterInfo }),
  getMasterDataFailure: (error: any) => createAction(ProductPricingActionTypes.GET_MASTER_INFO_FAILURE, { error }),

  exportProductPricing: () => createAction(ProductPricingActionTypes.PRODUCT_PRICING_EXPORT),
  exportProductPricingSuccess: (row: number, message?: string) =>
    createAction(ProductPricingActionTypes.PRODUCT_PRICING_EXPORT_SUCCESS, { row, message }),
  exportProductPricingFailure: (error: any) =>
    createAction(ProductPricingActionTypes.PRODUCT_PRICING_EXPORT_FAILURE, {
      error,
    }),

  importProductPrice: () => createAction(ProductPricingActionTypes.IMPORT_PRODUCT_PRICING),
  importProductPriceSuccess: (message?: string) =>
    createAction(ProductPricingActionTypes.IMPORT_PRODUCT_PRICING_SUCCESS, {
      message,
    }),
  importProductPriceFailure: (error: any) =>
    createAction(ProductPricingActionTypes.IMPORT_PRODUCT_PRICING_FAILURE, {
      error,
    }),
};

export type ProductPricingActions = ActionUnion<typeof productPricingActions>;

/**
 * Get all import logs
 * @returns
 */
export const getImportLogsAsync = (condition: SearchCondition): ThunkAction => {
  return async (dispatch) => {
    dispatch(productPricingActions.getImportLogs());
    try {
      const data = await getLogs(condition);
      dispatch(productPricingActions.getImportLogsSuccess(data.results));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(productPricingActions.getImportLogsFailure(errorText));
    }
  };
};

/**
 * Get all product price
 * @returns
 */
export const getProductPricingAsync = (condition: SearchCondition): ThunkAction => {
  return async (dispatch) => {
    dispatch(productPricingActions.getProductPricing());
    try {
      const data = await getProductPrices(condition);
      dispatch(productPricingActions.getProductPricingSuccess(data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(productPricingActions.getProductPricingFailure(errorText));
    }
  };
};

export const getMasterInfoAsync = (): ThunkAction => {
  return async (dispatch): Promise<any> => {
    dispatch(productPricingActions.getMasterData());
    try {
      const data = await getSetting(MASTER_TYPE.EX_PRODUCT_PRICING);
      dispatch(productPricingActions.getMasterDataSuccess(data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(productPricingActions.getMasterDataFailure(errorText));
    }
  };
};

/**
 * export product pricing
 * @returns
 */
export const exportProductPricingAsync = (condition: SearchCondition): ThunkAction => {
  return async (dispatch) => {
    dispatch(productPricingActions.exportProductPricing());
    try {
      const fileResponse: any = await exportFile(condition);
      const warningMessage = await getApiSuccess(fileResponse);
      if (isEmpty(warningMessage)) {
        const rowNumber = fileResponse.headers['row-number'];
        const fileName = getFileName(fileResponse.headers['content-disposition']);
        const blob = new Blob([fileResponse.data]);
        saveAs(blob, fileName);
        dispatch(productPricingActions.exportProductPricingSuccess(rowNumber));
      } else {
        dispatch(productPricingActions.exportProductPricingSuccess(0, warningMessage));
      }
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(productPricingActions.exportProductPricingFailure(errorText));
    }
  };
};

/**
 * Import product price
 * @returns
 */
export const importProductPriceAsync = (file: File): ThunkAction => {
  return async (dispatch): Promise<any> => {
    dispatch(productPricingActions.importProductPrice());
    try {
      const response: any = await importProduct('/import/product_pricing', file);
      dispatch(productPricingActions.importProductPriceSuccess(response?.message));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(productPricingActions.importProductPriceFailure(errorText));
    }
  };
};
