import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useNavigate, matchPath, useLocation } from 'react-router-dom';
import { isArrayValue, isEmpty, MENU, MENU_HREF } from '../../core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      borderRadius: 10,
    },
    mobileMenu: {
      borderRadius: '0.25rem',
      backgroundColor: theme.palette.common.white,
      width: '50%',
      '& .MuiListItemIcon-root': {
        minWidth: 'auto',
        marginRight: '0.5rem',
      },
      '& .MuiListItemButton-root': {
        paddingTop: 0,
        paddingBottom: 0,
        marginLeft: '0.5rem',
      },
    },
    menuItem: {
      '&:hover': {
        backgroundColor: (theme.palette.common as any).color.gray,
        borderRadius: 0,
      },
      '& .MuiListItemIcon-root': {
        minWidth: 'auto',
        marginRight: '0.5rem',
      },
    },
    menuText: {
      color: (theme.palette.common as any).color.textPrimary,
    },
    box: {
      padding: 5,
    },
    buttonTransform: {
      textTransform: 'none',
      color: '#FFFFFF8C',
      '&:hover, &:focus': {
        color: theme.palette.primary.contrastText,
      },
    },
    buttonActive: {
      textTransform: 'none',
      color: theme.palette.primary.contrastText,
      '&:hover, &:focus': {
        color: theme.palette.primary.contrastText,
      },
    },
  }),
);

interface NavProps {
  label: string;
  menuItems?: any;
  icon?: JSX.Element;
  href: string;
  relativeRef?: string;
  identifyKey: string;
  isMobile: boolean;
}

export const NavItem = (props: NavProps) => {
  const classes = useStyles();
  const { label, icon, menuItems, href, identifyKey, isMobile, relativeRef } = props;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleOpen = (e: any) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
    onNavigate(identifyKey, href);
  };

  const handleClose = (e: any) => {
    setAnchorEl(null);
    setOpen(false);
  };

  const usePathPattern = () => {
    return isEmpty(menuItems)
      ? !isEmpty(matchPath(href, pathname)) || (relativeRef && !isEmpty(matchPath(relativeRef, pathname)))
      : menuItems.some((x: any) => !isEmpty(matchPath(x.href, pathname)));
  };

  const selected = usePathPattern();

  const onNavigate = (identifyKey: string, href: string) => {
    switch (identifyKey) {
      case MENU.PURCHASE_LIMIT:
      case MENU.REBATE_AMOUNT:
      case MENU.QR_DOCUMENT:
      case MENU.PRODUCT_PRICING:
        navigate(href);
        break;
      case MENU.IMPORT:
      case MENU.EXPORT:
      case MENU.SETTING:
        break;
      default:
        navigate(MENU_HREF.DASHBOARD);
        break;
    }
  };

  const onSubClick = (menu: any) => {
    switch (menu.identifyKey) {
      case MENU.EVENT_LOG:
        navigate(menu.href);
        break;
      case MENU.ACCESS_LOG:
        navigate(menu.href);
        break;
      case MENU.ORDER_DATA_OUTPUT:
        navigate(href, { state: { contentId: 'order' } });
        break;
      case MENU.REBATE_OUTPUT:
        navigate(href, { state: { contentId: 'rebate' } });
        break;
      case MENU.IMPORT_FILES_GOOD:
        navigate(href, { state: { contentId: 'goods' } });
        break;
      case MENU.IMPORT_FILES_INVENTORY:
        navigate(href, { state: { contentId: 'inventory' } });
        break;
      case MENU.IMPORT_FILES_CALENDAR:
        navigate(href, { state: { contentId: 'businessCalendar' } });
        break;
      default:
        navigate(MENU_HREF.DASHBOARD);
        break;
    }
  };

  const hasSubMenu = !isEmpty(menuItems) && isArrayValue(menuItems);

  return (
    <>
      <Button
        onClick={handleOpen}
        startIcon={icon}
        className={selected ? classes.buttonActive : classes.buttonTransform}
      >
        {label}
        {hasSubMenu && <>{open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</>}
      </Button>
      {hasSubMenu && (
        <>
          {isMobile && open ? (
            <List classes={{ root: classes.mobileMenu }}>
              {menuItems.map((menu: any) => (
                <ListItem
                  className={classes.menuItem}
                  disablePadding
                  key={menu.identifyKey}
                  onClick={() => onSubClick(menu)}
                >
                  <ListItemButton>
                    <ListItemIcon>{menu.icon}</ListItemIcon>
                    <ListItemText className={classes.menuText} primary={menu.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          ) : (
            <Menu
              elevation={1}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              classes={{
                paper: classes.menu,
              }}
              MenuListProps={{
                onClick: handleClose,
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box className={classes.box}>
                {menuItems.map((menu: any) => (
                  <MenuItem key={menu.identifyKey} onClick={() => onSubClick(menu)} className={classes.menuItem}>
                    <ListItemIcon>{menu.icon}</ListItemIcon>
                    <Typography variant='inherit' className={classes.menuText}>
                      {menu.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Box>
            </Menu>
          )}
        </>
      )}
    </>
  );
};
