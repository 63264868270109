import { Box, Button, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { SearchIcon, TextFieldControl } from '../../../../common';
import { useFormik } from 'formik';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CombinedState, isEmpty } from '../../../../core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textHeader: {
      fontSize: '1.5rem',
    },
    linkDescription: {
      marginTop: '-0.1em !important',
      fontSize: '0.9rem',
    },
    linkText: {
      color: (theme.palette.common as any).color.primary,
    },
    buttonOutlined: {
      '&:active,&:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
    },
    textDanger: {
      color: (theme.palette.common as any).color.textDanger,
    },
    infoText: {
      color: (theme.palette.common as any).color.primary,
    },
  }),
);
interface SearchFormProps {
  onSubmit: (value: any) => void;
  searchState: any;
  isChangeCount: Boolean;
}

export const SearchFormComponent = (props: SearchFormProps) => {
  const classes = useStyles();
  const { onSubmit, searchState, isChangeCount } = props;
  const formRef = useRef<any>();
  const { productPrices, fetching } = useSelector((state: CombinedState) => state['productPricing']);

  const handleSubmit = (value: any) => {
    onSubmit(value);
  };

  formRef.current = useFormik({
    initialValues: {
      salesRouteType: '',
      janCode: '',
    },
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (!isEmpty(searchState)) {
      formRef.current.setValues({
        salesRouteType: searchState?.salesRouteType ?? '',
        janCode: searchState?.janCode ?? '',
      });
    } else return;
  }, [searchState]);

  const hasSearchCriteria = () => {
    return !isEmpty(searchState.salesRouteType) || !isEmpty(searchState.janCode);
  };

  return (
    <form onSubmit={formRef.current.handleSubmit} noValidate>
      <Grid container>
        <Grid item xs={12}>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <SearchIcon fontSize='medium' viewBox={'0 0 16 16'} />
            <Typography ml={1} variant='h4' className={classes.textHeader}>
              商品価格の検索
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.linkDescription}>設定されている商品価格を検索できます。</Typography>
          <Typography className={classes.linkDescription}>
            全データを確認したい場合は、表示量が多いためインポート・エクスポートからデータをエクスポートしてご確認ください。
          </Typography>
        </Grid>
      </Grid>

      <Grid container mt={0.5} spacing={2}>
        <Grid item xs={3}>
          <TextFieldControl
            fullWidth
            id='salesRouteType'
            name='salesRouteType'
            type='text'
            label='受注元区分'
            size='small'
            value={formRef.current.values.salesRouteType}
            onChange={formRef.current.handleChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFieldControl
            fullWidth
            id='janCode'
            name='janCode'
            type='text'
            label='JANコード'
            size='small'
            value={formRef.current.values.janCode}
            onChange={formRef.current.handleChange}
          />
        </Grid>
        <Grid item xs={3}>
          <Button type='submit' variant='outlined' classes={{ outlined: classes.buttonOutlined }}>
            検索
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        {(!isChangeCount || !fetching) && hasSearchCriteria() && (
          <Grid item xs={12}>
            {productPrices.count === 0 ? (
              <Typography className={classes.textDanger} component='span'>
                指定された条件ではデータが存在しませんでした。
              </Typography>
            ) : (
              <Typography className={classes.infoText} component='span'>
                {`${productPrices.count}件のデータが存在しました。`}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
    </form>
  );
};
