import { ProductPricingPagination, apiService, SearchCondition } from '../../../core';

export const getProductPrices = async (condition: SearchCondition): Promise<ProductPricingPagination> => {
  return await apiService.get<ProductPricingPagination>('/productPricingList', { ...condition.filter });
};

export const exportFile = async (condition: SearchCondition) => {
  return await apiService.getFile<any>('/export/product_pricing', { ...condition.filter }, { responseType: 'blob' });
};

export const importProduct = async (url: string, file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return await apiService.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
