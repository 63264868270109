import {
  AppBar,
  Box,
  Button,
  Stack,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useScrollTrigger,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutAsync, MENU, MENU_HREF } from '../../core';
import {
  SpeedometerIcon,
  CalculatorIcon,
  CartIcon,
  JournalDownIcon,
  JournalUpIcon,
  GearIcon,
  BagCheckIcon,
  ShopWindowIcon,
  CalendarEventIcon,
  InfoIcon,
  ExclamationIcon,
  PiggyIcon,
  SmileIcon,
  Basket2Icon,
} from '../icons';
import { NavItem } from './nav-item';
import { Explicit, Menu } from '@mui/icons-material';
import { useTheme } from '@mui/system';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundImage: 'linear-gradient(90deg, rgba(0, 91, 172, 1) 80%, rgba(83, 195, 241, 1))',
  },
  toolbar: {
    minHeight: '50px',
  },
  mobileToolbar: {
    minHeight: '50px',
    flexDirection: 'column',
  },
  flexGrow1: {
    flexGrow: 1,
  },
  logo: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '0.25em',
    fontSize: '1.25rem',
    color: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  logoutBtn: {
    color: '#f8f9fa',
    borderColor: '#f8f9fa',
    '&:hover': {
      color: '#000000',
      backgroundColor: '#f8f9fa',
    },
  },
  logoutTile: {
    backgroundColor: (theme.palette.common as any).color.primary,
    color: '#fff',
  },
  buttonOutlined: {
    '&:active, &:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: (theme.palette.common as any).color.outlined,
    },
  },
  noButton: {
    color: '#6c757d',
    borderColor: '#6c757d',
    '&:active, &:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: '#6c757d',
      borderColor: '#6c757d',
    },
  },
}));

interface Props {
  children: React.ReactElement;
}

interface UserProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
}

function ElevationScroll(props: Props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const NormalToolbar = (props: any) => {
  const classes = useStyles();
  const { handleLogout, menuList, onGoHome } = props;

  return (
    <Toolbar className={classes.toolbar}>
      <Typography component='a' onClick={onGoHome} className={classes.logo}>
        LSC-EC
        <Explicit />
      </Typography>

      <Stack direction='row' columnGap={3} sx={{ ml: 12 }}>
        {menuList.map((menu: any) => (
          <NavItem key={menu.identifyKey} {...menu} isMobile={false} />
        ))}
      </Stack>
      <Box className={classes.flexGrow1} />
      <Button variant='outlined' size='small' className={classes.logoutBtn} onClick={handleLogout}>
        ログアウト
      </Button>
    </Toolbar>
  );
};

const MobileToolbar = (props: any) => {
  const classes = useStyles();
  const { handleLogout, menuList, onGoHome } = props;
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  return (
    <Toolbar className={classes.mobileToolbar}>
      <Stack width='100%' direction='row' justifyContent='space-between'>
        <Typography component='a' onClick={onGoHome} className={classes.logo}>
          LSC-EC
          <Explicit />
        </Typography>

        <IconButton size='large' edge='end' color='inherit' onClick={() => setOpenMenu(!openMenu)}>
          <Menu />
        </IconButton>
      </Stack>

      {openMenu && (
        <Stack width='100%' direction='column' alignItems='flex-start' ml={12} pb={3}>
          {menuList.map((menu: any) => (
            <NavItem key={menu.identifyKey} {...menu} isMobile={true} />
          ))}
          <Button
            sx={{ marginTop: 1 }}
            variant='outlined'
            size='small'
            className={classes.logoutBtn}
            onClick={handleLogout}
          >
            ログアウト
          </Button>
        </Stack>
      )}
    </Toolbar>
  );
};

export function NavBar(props: UserProps) {
  const menuList = [
    {
      label: 'ダッシュボード',
      menuItems: [],
      icon: <SpeedometerIcon fontSize='medium' viewBox={'0 0 16 16'} />,
      relativeRef: MENU_HREF.ROOT,
      href: MENU_HREF.DASHBOARD,
      identifyKey: MENU.DASHBOARD,
    },
    {
      label: '購入上限',
      menuItems: [],
      icon: <CartIcon fontSize='medium' viewBox={'0 0 16 16'} />,
      href: MENU_HREF.PURCHASE_LIMIT,
      identifyKey: MENU.PURCHASE_LIMIT,
    },
    {
      label: '栄養指導料',
      menuItems: [],
      icon: <CalculatorIcon fontSize='medium' viewBox={'0 0 16 16'} />,
      href: MENU_HREF.REBATE_AMOUNT,
      identifyKey: MENU.REBATE_AMOUNT,
    },
    {
      label: '企業商品価格',
      icon: <Basket2Icon fontSize='small' viewBox={'0 0 16 16'} />,
      identifyKey: MENU.PRODUCT_PRICING,
      href: MENU_HREF.PRODUCT_PRICING,
    },
    {
      label: '取り込み',
      menuItems: [
        {
          label: '商品体系４情報',
          icon: <BagCheckIcon fontSize='small' viewBox={'0 0 16 16'} />,
          identifyKey: MENU.IMPORT_FILES_GOOD,
          href: MENU_HREF.IMPORT,
        },
        {
          label: '在庫情報',
          icon: <ShopWindowIcon fontSize='small' viewBox={'0 0 16 16'} />,
          identifyKey: MENU.IMPORT_FILES_INVENTORY,
          href: MENU_HREF.IMPORT,
        },
        {
          label: '営業日カレンダー',
          icon: <CalendarEventIcon fontSize='small' viewBox={'0 0 16 16'} />,
          identifyKey: MENU.IMPORT_FILES_CALENDAR,
          href: MENU_HREF.IMPORT,
        },
      ],
      icon: <JournalDownIcon fontSize='medium' viewBox={'0 0 16 16'} />,
      identifyKey: MENU.IMPORT,
      href: MENU_HREF.IMPORT,
    },
    {
      label: '書き出し',
      menuItems: [
        {
          label: '注文データ',
          icon: <BagCheckIcon fontSize='small' viewBox={'0 0 16 16'} />,
          identifyKey: MENU.ORDER_DATA_OUTPUT,
          href: MENU_HREF.EXPORT,
        },
        {
          label: '栄養指導料支払',
          icon: <PiggyIcon fontSize='small' viewBox={'0 0 16 16'} />,
          identifyKey: MENU.REBATE_OUTPUT,
          href: MENU_HREF.EXPORT,
        },
      ],
      icon: <JournalUpIcon fontSize='medium' viewBox={'0 0 16 16'} />,
      href: MENU_HREF.EXPORT,
      identifyKey: MENU.EXPORT,
    },
    {
      label: '設定',
      menuItems: [
        {
          label: 'アクセスログ',
          icon: <InfoIcon fontSize='small' viewBox={'0 0 16 16'} />,
          identifyKey: MENU.ACCESS_LOG,
          href: MENU_HREF.ACCESS_LOG,
        },
        {
          label: '処理状況ログ',
          icon: <ExclamationIcon fontSize='small' viewBox={'0 0 16 16'} />,
          identifyKey: MENU.EVENT_LOG,
          href: MENU_HREF.EVENT_LOG,
        },
      ],
      icon: <GearIcon fontSize='medium' viewBox={'0 0 16 16'} />,
      href: '/setting',
      identifyKey: MENU.SETTING,
    },
  ];
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleLogout = () => {
    setOpen(true);
  };

  const onLogout = () => {
    dispatch(logoutAsync());
  };

  const onClose = () => {
    setOpen(false);
  };

  const onGoHome = () => {
    navigate(MENU_HREF.ROOT);
  };

  return (
    <ElevationScroll>
      <AppBar elevation={0} position='fixed' className={classes.appBar}>
        {matches ? (
          <MobileToolbar menuList={menuList} handleLogout={handleLogout} onGoHome={onGoHome} />
        ) : (
          <NormalToolbar menuList={menuList} handleLogout={handleLogout} onGoHome={onGoHome} />
        )}
        <Dialog fullWidth={true} maxWidth='sm' open={open} onClose={onClose}>
          <DialogTitle classes={{ root: classes.logoutTile }}>ログアウト</DialogTitle>
          <DialogContent dividers>
            <Box my={1} display='flex' flexDirection='row' alignItems='center'>
              <Typography mr={1}>お疲れ様でした </Typography>
              <SmileIcon fontSize='medium' viewBox={'0 0 16 16'} />
            </Box>
            <Typography>ログアウトしますか？</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' classes={{ outlined: classes.noButton }} onClick={onClose}>
              いいえ
            </Button>
            <Button variant='outlined' classes={{ outlined: classes.buttonOutlined }} onClick={onLogout}>
              はい
            </Button>
          </DialogActions>
        </Dialog>
      </AppBar>
    </ElevationScroll>
  );
}

export default NavBar;
