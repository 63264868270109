import React from 'react';
import { downloadLogFileAsync, isEmpty, LogInfo, LOG_LEVEL } from '../../core';
import parse from 'html-react-parser';
import { Link } from '@mui/material';
import { useDispatch } from 'react-redux';

interface LogMessageProps {
  log: LogInfo;
  sender: string;
}

export const LogMessage = React.memo((props: LogMessageProps) => {
  const { log, sender } = props;
  const dispatch = useDispatch();

  const onClick = (filePath: string, fileName: string, dataType: string) => {
    const file = {
      filePath: filePath,
      fileName: fileName,
      dataType: dataType,
    };
    dispatch(downloadLogFileAsync(file, sender));
  };

  const options = {
    replace: (domNode: any) => {
      if (domNode?.name === 'a') {
        const { data } = domNode.children[0] as any;
        return (
          <Link onClick={() => onClick(domNode.attribs['data-path'], data, domNode.attribs['data-type'])}>{data}</Link>
        );
      }
    },
  };

  // validate if tag a
  const validateNode = (systemMessage: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(systemMessage, "text/html");
    const aNode = doc.querySelector("a");
    return aNode ? true : false;
  }

  return <>{validateNode(log.systemMessage) ? parse(log.systemMessage, options) : log.systemMessage}</>;
});

export const getLogTitle = (props: LogInfo) => {
  const { functionName, methodName } = props;
  if (isEmpty(methodName)) return `${functionName}`;
  else {
    return `${functionName}:${methodName}`;
  }
};

export const getLogLevel = (level: number) => {
  switch (level) {
    case LOG_LEVEL.SUCCESS:
      return 'SUCCESS';
    case LOG_LEVEL.WARNING:
      return 'WARNING';
    case LOG_LEVEL.ERROR:
      return 'ERROR';
    case LOG_LEVEL.DEBUG:
      return 'DEBUG';
    default:
      return 'INFORMATION';
  }
};
