import { ProductPricingActionTypes, ProductPricingActions } from './actions';
import { ProductPricingState } from './states';

const defaultState: ProductPricingState = {
  fetching: false,
  importLogs: [],
  productPrices: {
    count: 0,
    results: [],
  },
  productPricingFetching: false,
  exportProductPricingFetching: undefined,
};

export const productPricingReducer = (state = defaultState, action: ProductPricingActions): ProductPricingState => {
  switch (action.type) {
    case ProductPricingActionTypes.GET_IMPORT_LOGS:
      return {
        ...state,
        fetching: true,
      };
    case ProductPricingActionTypes.GET_IMPORT_LOGS_SUCCESS:
      return {
        ...state,
        fetching: false,
        importLogs: action.payload.data,
      };
    case ProductPricingActionTypes.GET_IMPORT_LOGS_FAILURE:
      return {
        ...state,
        fetching: false,
      };

    case ProductPricingActionTypes.GET_PRODUCT_PRICING:
      return {
        ...state,
        fetching: true,
      };
    case ProductPricingActionTypes.GET_PRODUCT_PRICING_SUCCESS:
      return {
        ...state,
        fetching: false,
        productPrices: action.payload.data,
      };
    case ProductPricingActionTypes.GET_PRODUCT_PRICING_FAILURE:
      return {
        ...state,
        fetching: false,
      };

    case ProductPricingActionTypes.GET_MASTER_INFO_SUCCESS:
      return {
        ...state,
        masterInfo: action.payload.masterInfo,
      };

    case ProductPricingActionTypes.IMPORT_PRODUCT_PRICING:
      return {
        ...state,
        productPricingFetching: true,
      };
    case ProductPricingActionTypes.IMPORT_PRODUCT_PRICING_SUCCESS:
    case ProductPricingActionTypes.IMPORT_PRODUCT_PRICING_FAILURE:
      return {
        ...state,
        productPricingFetching: false,
      };

    case ProductPricingActionTypes.PRODUCT_PRICING_EXPORT:
      return {
        ...state,
        exportProductPricingFetching: true,
      };
    case ProductPricingActionTypes.PRODUCT_PRICING_EXPORT_SUCCESS:
    case ProductPricingActionTypes.PRODUCT_PRICING_EXPORT_FAILURE:
      return {
        ...state,
        exportProductPricingFetching: false,
      };
    default:
      return state;
  }
};
